import React, { useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Descriptions, Space, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { SystemModel, useFetcher } from '../../../lib';
import { Button, Content, Loading, SpaceBetween } from '../../ui';
import { SystemEditModal } from '../SystemEditModal';
import { PropertiesList } from '../../properties';

export const System = () => {
  const navigate = useNavigate();
  const { systemId } = useParams();
  const [editSystem, setEditSystem] = useState(false);
  const fetcher = useFetcher();
  const {
    data,
    error,
    isLoading
  } = useSWR('systems/' + systemId, () => fetcher.get<SystemModel>('/systems/' + systemId));
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <Content>
      <SpaceBetween>
        <Typography.Title level={5}>
          <Space>
            <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
            {data.data.name}
          </Space>
        </Typography.Title>
        <Space>
          <Button onClick={() => setEditSystem(true)}>Edit</Button>
        </Space>
      </SpaceBetween>
      <Descriptions layout='vertical'>
        <Descriptions.Item label='Name'>{data.data.name || '-'}</Descriptions.Item>
      </Descriptions>
      <SystemEditModal isOpen={editSystem} setOpen={setEditSystem} systemId={systemId || '-'} />
      <PropertiesList systemId={systemId || '-'} />
    </Content>
  );
};
