import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { useFetcher } from '../../../../lib';
import { Button, Loading, SpaceBetween } from '../../../../components';
import { ConsumerModel } from '../../types';

import './ConsumerSwitcher.scss';
import { Space, Typography } from 'antd';
import { RegisterModal } from '../RegisterModal';

export const ConsumerSwitcher = () => {
  const [addConsumer, setAddConsumer] = useState(false);
  const fetcher = useFetcher();
  const { data, error, isLoading } = useSWR('consumer/consumers', () => fetcher.get<ConsumerModel[]>('/consumers'));
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <div className='pc-consumer-switcher'>
      <SpaceBetween>
        <Typography.Title level={5}>Consumers</Typography.Title>
        <Space>
          <Button onClick={() => setAddConsumer(true)}>Register</Button>
        </Space>
      </SpaceBetween>
      {data.data.map(consumer => (
        <Link to={`${consumer.id}`}>{`${consumer.firstName} ${consumer.lastName}`}</Link>
      ))}
      <RegisterModal isOpen={addConsumer} setOpen={setAddConsumer} />
    </div>
  );
};
