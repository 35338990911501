import React, {createContext, ReactNode, useContext} from 'react';
import axios, {AxiosInstance} from 'axios';

const FetcherContext = createContext(null as unknown as AxiosInstance);

type FetcherProviderProps = {
    baseURL: string;
    children: ReactNode;
    token: string;
}

export const FetcherProvider = ({baseURL, children, token}: FetcherProviderProps) => {
    const fetcher = axios.create({
        baseURL: baseURL,
    });
    fetcher.interceptors.request.use(
        config => {
            config.headers['x-api-key'] = token;
            return config;
        }
    );
    return (
        <FetcherContext.Provider value={fetcher}>
            {children}
        </FetcherContext.Provider>
    );
}

export const useFetcher = () => {
    return useContext(FetcherContext);
}
