import React from 'react';
import {SyncOutlined} from '@ant-design/icons';
import {Space} from "antd";

import './Loading.scss';

export const Loading = () => {
    return (
        <div className="pc-loading">
            <Space>
                Loading...
                <SyncOutlined spin/>
            </Space>
        </div>
    );
};
