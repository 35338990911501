import React, { useState } from 'react';
import { Button } from '../../../../components';
import { PropertyModel, useFetcher } from '../../../../lib';
import { Form, Modal } from 'antd';

export const FORMS_INQUIRED_OPTIONS = [
  { label: 'Property history' },
  { label: 'Property state' },
  { label: 'Communal payments' }
];

export const FORMS_OFFER_OPTIONS = [
  { label: 'Offer message' }
];

export const FORMS_SHOW_REQUEST_OPTIONS = [
  { label: 'Showing request (Prime)' },
  { label: 'Showing request' }
];

type FormSwitcherProps = {
  title: string
  action: 'offer' | 'showing' | 'inquired'
  property?: PropertyModel;
  consumerId: string;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  forms: { label: string }[]
  inGeneral?: boolean
}

export const FormSwitcher = ({
                               forms,
                               consumerId,
                               property,
                               isOpen,
                               title,
                               setOpen,
                               action,
                               inGeneral
                             }: FormSwitcherProps) => {
  const [form, setForm] = useState(forms[0]);
  const [value, setValue] = useState('');
  const fetcher = useFetcher();
  const [formModal] = Form.useForm();

  return (
    <Modal
      onCancel={() => setOpen(false)}
      onOk={() => {

        let url = property && `${consumerId}/properties/${property.id}/${action}`;
        if (inGeneral) {
          url = `${consumerId}/settings/inquired-general`;
        }
        if (!url) return;

        fetcher.post(url, {
          formName: form.label,
          formText: value
        })
          .then(() => {
            setOpen(false);
          });
      }}
      open={isOpen}
      title={title}
    >
      <Form
        form={formModal}
      >
        <div style={{ display: 'flex' }}>
          {forms.map((formOption, index) =>
            <div key={index} style={{
              backgroundColor: form.label === formOption.label ? 'blue' : 'gray',
              margin: 5,
              padding: 10
            }}>
              <Button
                onClick={() => {
                  setForm(formOption);
                }}>
                {formOption.label}
              </Button>
            </div>
          )}
        </div>
        <textarea style={{ width: 450, height: 200 }} value={value}
                  onChange={(event) => setValue(event.target.value)} />
      </Form>
    </Modal>

  );
};



