import { useState } from 'react';
import { FORMS_INQUIRED_OPTIONS, FormSwitcher } from '../FormSwitcher/FormSwitcher';
import { Button } from '../../../../components';

export const InquiredInGeneralRequest = ({ consumerId }: { consumerId: string; }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>Add Inquired About Properties In General</Button>
      <FormSwitcher
        title={'Inquired In General About Properties'}
        forms={FORMS_INQUIRED_OPTIONS}
        isOpen={open}
        setOpen={setOpen}
        action={'inquired'}
        consumerId={consumerId}
        inGeneral={true}
      />
    </>

  );
};