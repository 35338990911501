import React from 'react';
import { Navigate, NavLink, Outlet, Route, Routes } from 'react-router-dom';
import { Fetcher } from '../../lib';
import { Broker, BrokersList, Consumer, ConsumersList, Property, System, SystemsList } from '../../components';

const ADMIN_MENU = [{
  key: 'brokers',
  label: <NavLink to={'/admin/brokers'}>Brokers</NavLink>
}, {
  key: 'systems',
  label: <NavLink to={'/admin/systems'}>MLS Systems</NavLink>
}, {
  key: 'consumers',
  label: <NavLink to={'/admin/consumers'}>Consumers</NavLink>
}];

type RootPageProps = {
  removeToken: () => void;
  token: string;
}

export const RootPage = ({ removeToken, token }: RootPageProps) => {
  return (
    <Fetcher baseURL='/api/admin' removeToken={removeToken} token={token}>
      <Routes>
        <Route index={true} element={<Navigate to='brokers' />} />
        <Route path='brokers'>
          <Route index={true} element={<BrokersList menu={ADMIN_MENU} />} />
          <Route path=':brokerId' element={<Broker />} />
        </Route>
        <Route path='consumers'>
          <Route index={true} element={<ConsumersList menu={ADMIN_MENU} />} />
          <Route path=':consumerId' element={<Consumer />} />
        </Route>
        <Route path='properties'>
          <Route path=':propertyId' element={<Property />} />
        </Route>
        <Route path='systems'>
          <Route index={true} element={<SystemsList menu={ADMIN_MENU} />} />
          <Route path=':systemId' element={<System />} />
        </Route>
      </Routes>
      <Outlet />
    </Fetcher>
  );
};
