import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { formatAddress, useFetcher } from '../../../lib';
import useSWR from 'swr';
import { PropertyModel } from '../../../lib';
import { Button, Content, Loading, SpaceBetween } from '../../ui';
import { Descriptions, Space, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { PropertyActivitiesList } from '../PropertyActivitiesList';
import { PropertyEditModal } from '../PropertyEditModal';

export const Property = () => {
  const navigate = useNavigate();
  const { propertyId } = useParams();
  const [editProperty, setEditProperty] = useState(false);
  const fetcher = useFetcher();
  const {
    data,
    error,
    isLoading
  } = useSWR(`properties/${propertyId}`, () => fetcher.get<PropertyModel>(`/properties/${propertyId}`));
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <Content>
      <SpaceBetween>
        <Typography.Title level={5}>
          <Space>
            <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
            {data.data.mlsNumber}
          </Space>
        </Typography.Title>
        <Space>
          <Button onClick={() => setEditProperty(true)}>Edit</Button>
        </Space>
      </SpaceBetween>
      <Descriptions layout='vertical'>
        <Descriptions.Item label='MLS Number'>{data.data.mlsNumber || '-'}</Descriptions.Item>
        <Descriptions.Item label='Current Price'>{data.data.listPrice || '-'}</Descriptions.Item>
        <Descriptions.Item label='Original Price'>{data.data.originalListPrice || '-'}</Descriptions.Item>
        <Descriptions.Item label='Listing Date'>{data.data.listingDate || '-'}</Descriptions.Item>
        <Descriptions.Item label='Expiration Date' span={2}>{data.data.expirationDate || '-'}</Descriptions.Item>
        <Descriptions.Item label='Property Type'>{data.data.propertyType || '-'}</Descriptions.Item>
        <Descriptions.Item label='Transaction Type'>{data.data.transactionType || '-'}</Descriptions.Item>
        <Descriptions.Item label='Status'>{data.data.status || '-'}</Descriptions.Item>
        <Descriptions.Item label='Address'>{formatAddress(data.data.address)}</Descriptions.Item>
        <Descriptions.Item label='Neighborhood'>{data.data.neighborhood}</Descriptions.Item>
        <Descriptions.Item label='Property URL'>{data.data.propertyURL}</Descriptions.Item>
        <Descriptions.Item label='Bedrooms'>{data.data.bedrooms}</Descriptions.Item>
        <Descriptions.Item label='Bathrooms'>{data.data.bathrooms}</Descriptions.Item>
        <Descriptions.Item label='Square Footage'>{data.data.squareFootage}</Descriptions.Item>
      </Descriptions>
      <PropertyActivitiesList propertyId={propertyId || '-'} />
      <PropertyEditModal isOpen={editProperty} propertyId={propertyId || '-'} setOpen={setEditProperty} />
    </Content>
  );
};
