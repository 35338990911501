import React, { useState } from 'react';
import { Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { SystemModel, useFetcher } from '../../../lib';
import { Button, Content, Loading, SpaceBetween } from '../../ui';
import { SystemAddModal } from '../SystemAddModal';

type SystemsByBrokerListProps = {
  brokerId: string
}

export const SystemsByBrokerList = ({brokerId}: SystemsByBrokerListProps) => {
  const [addSystem, setAddSystem] = useState(false);
  const fetcher = useFetcher();
  const { data, error, isLoading } = useSWR('systems', () => fetcher.get<SystemModel[]>(`/brokers/${brokerId}/systems`));
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <Content>
      <SpaceBetween>
        <Typography.Title level={5}>MLS Systems</Typography.Title>
        <Space>
          <Button onClick={() => setAddSystem(true)}>Create MLS System</Button>
        </Space>
      </SpaceBetween>
      <Table dataSource={data.data}>
        <Table.Column<SystemModel> title='Name' dataIndex='name' key='name' render={(_, system) => (
          <Link to={`/admin/systems/${system.id}`}>{system.name}</Link>
        )} />
      </Table>
      <SystemAddModal isOpen={addSystem} setOpen={setAddSystem} />
    </Content>
  );
};
