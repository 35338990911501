import React from 'react';
import {ModuleSelector} from "../components";

export const RootPage = () => {
    return (
        <ModuleSelector modules={[
            {
                name: 'Admin Module',
                url: '/admin',
            },
            {
                name: 'Consumer Module',
                url: '/consumer',
            },
        ]} />
    );
};
