import React, { useState } from 'react';
import { Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { BrokerModel, useFetcher } from '../../../lib';
import { Button, Content, Loading, SpaceBetween } from '../../ui';
import { BrokerAddModal } from '../BrokerAddModal';
import { ItemType } from 'antd/es/menu/hooks/useItems';

const { Column } = Table;

type BrokersListProps = {
  menu?: ItemType[];
}

export const BrokersList = ({ menu }: BrokersListProps) => {
  const [addBroker, setAddBroker] = useState(false);
  const fetcher = useFetcher();
  const { data, error, isLoading } = useSWR('brokers', () => fetcher.get<BrokerModel[]>('/brokers'));
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <Content menu={menu}>
      <SpaceBetween>
        <Typography.Title level={5}>Brokers</Typography.Title>
        <Space>
          <Button onClick={() => setAddBroker(true)}>Create Broker</Button>
        </Space>
      </SpaceBetween>
      <Table dataSource={data.data}>
        <Column<BrokerModel> title='Name' dataIndex='name' key='name' render={(_, broker) => (
          <Link to={`${broker.id}`}>{broker.name}</Link>
        )} />
      </Table>
      <BrokerAddModal isOpen={addBroker} setOpen={setAddBroker} />
    </Content>
  );
};
