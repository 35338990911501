import {useState} from "react";
import {FORMS_SHOW_REQUEST_OPTIONS, FormSwitcher} from "../FormSwitcher/FormSwitcher";
import {PropertyModel} from "../../../../lib";
import {Button} from "../../../../components";

export const ShowingRequest = ({property, consumerId}: {
    property: PropertyModel;
    consumerId: string;
}) => {
    const [open, setOpen] = useState(false)

    return (
        <>
            <Button onClick={() => setOpen(true)}>Showing request</Button>
            <FormSwitcher
                title={'Showing Request'}
                forms={FORMS_SHOW_REQUEST_OPTIONS}
                isOpen={open}
                setOpen={setOpen}
                action={'showing'}
                property={property}
                consumerId={consumerId}/>
        </>

    )
}