import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { TokenProvider } from './lib';
import { RootPage } from './pages';
import { RootPage as AdminRoot } from './pages/admin';
import { RootPage as ConsumerRoot } from './pages/consumer';

import 'antd/dist/reset.css';
import './App.css';

function App() {
  return (
    <TokenProvider>
      {(token, removeToken) => (
        <BrowserRouter>
          <Routes>
            <Route path='/' index={true} element={<RootPage />} />
            <Route path='/admin/*' element={<AdminRoot removeToken={removeToken} token={token} />} />
            <Route path='/consumer/*' element={<ConsumerRoot removeToken={removeToken} token={token} />} />
          </Routes>
        </BrowserRouter>
      )}
    </TokenProvider>
  );
}

export default App;
