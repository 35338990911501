import React from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { PropertyModel, useFetcher } from '../../../../../../lib';
import { Loading } from '../../../../../../components';
import { Favorite } from '../../../Favorite';
import { SentToFriendAction } from '../../../SentToFriendAction';
import { SearchRequest } from '../../types';
import { DrivingDirectionsAction } from '../../../DrivingDirectionsAction';

import './Properties.scss';

type PropertiesProps = {
  consumerId: string;
  search: SearchRequest
}

export const Properties = ({ consumerId, search }: PropertiesProps) => {
  const fetcher = useFetcher();
  const {
    data,
    error,
    isLoading
  } = useSWR('consumer/properties', () => fetcher.post<PropertyModel[]>(consumerId + '/properties', search));
  const favorites = useSWR(`consumer/${consumerId}/favorites`, () => fetcher.get<PropertyModel[]>(consumerId + '/favorites'));
  if (isLoading || favorites.isLoading) {
    return <Loading />;
  }
  if (error || !data || favorites.error || !(favorites.data)) {
    return <div>Error...</div>;
  }
  return (
    <div className='pc-properties'>
      {data.data.length === 0 && (
        <div className='empty'>No records</div>
      )}
      {data.data.map(property => (
        <div key={property.id} className='property'>
          <Link to={`properties/${property.id}`} className='picture'>
            <img src={property.pictures[0]} alt='Property View' />
          </Link>
          <div className='price'>
            ${property.listPrice}
          </div>
          <div className='status'>
            {property.status} | MLS# {property.mlsNumber}
          </div>
          <div className='address'>
            <Link to={`properties/${property.id}`}>
              {`${property.address.streetNumber} ${property.address.streetName}`}
            </Link>
          </div>
          <div className='description'>
            {property.bedrooms} beds | {property.bathrooms} baths | {property.squareFootage} sq ft
            | {property.propertyType}
          </div>
          <div className='actions'>
            <Favorite consumerId={consumerId} favorites={favorites.data!.data} property={property} size={30} />
            <SentToFriendAction consumerId={consumerId || '-'} propertyId={`${property.id}`} size={30} />
            <DrivingDirectionsAction consumerId={consumerId || '-'} propertyId={`${property.id}`} size={30} />
          </div>
        </div>
      ))}
    </div>
  );
};
