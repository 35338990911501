import React from 'react';
import { useFetcher } from '../../../lib';
import { Col, DatePicker, Form, Input, Modal, Row, Select } from 'antd';
import { mutate } from 'swr';
import dayjs from 'dayjs';
import { PropertyModel } from '../../../lib';

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 }
};

type PropertyAddModalProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  systemId: string;
}

export const PropertyAddModal = ({ isOpen, setOpen, systemId }: PropertyAddModalProps) => {
  const fetcher = useFetcher();
  const [form] = Form.useForm();
  return (
    <Modal
      onCancel={() => setOpen(false)}
      onOk={() => {
        fetcher.post(`/systems/${systemId}/properties`, toInput(form.getFieldsValue()))
          .then(() => {
            setOpen(false);
            form.resetFields();
            mutate(`systems/${systemId}/properties`);
          });
      }}
      open={isOpen}
      title='New Property'
      width={800}
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          address: {
            streetNumber: '',
            streetName: '',
            unit: '',
            buildingName: '',
            city: '',
            state: '',
            zip: '',
            country: ''
          },
          bathrooms: '',
          bedrooms: '',
          expirationDate: dayjs().add(1, 'month'),
          listingDate: dayjs(),
          listPrice: '0',
          mlsNumber: '',
          neighborhood: '',
          propertyType: 'Single family',
          propertyURL: '',
          squareFootage: '',
          status: 'ACTIVE',
          transactionType: 'Sale'
        }}
        layout='vertical'
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label='MLS Number' name='mlsNumber'>
              <Input autoFocus={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Price' name='listPrice'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label='Listing Date' name='listingDate'>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label='Expiration Date' name='expirationDate'>
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item label='Property Type' name='propertyType'>
              <Select options={[
                { value: 'Single family', label: 'Single family' },
                { value: 'Condo', label: 'Condo' },
                { value: 'Townhouse', label: 'Townhouse' },
                { value: 'Multi-family', label: 'Multi-family' },
                { value: 'Lot/Land', label: 'Lot/Land' },
                { value: 'Commercial', label: 'Commercial' },
                { value: 'Other', label: 'Other' }
              ]} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Transaction Type' name='transactionType'>
              <Select options={[
                { value: 'Sale', label: 'Sale' },
                { value: 'Rent', label: 'Rent' },
              ]} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label='Status' name='status'>
              <Select options={[
                { value: 'ACTIVE', label: 'Active' },
                { value: 'EXPIRED', label: 'Expired' },
                { value: 'PENDING', label: 'Pending' },
                { value: 'SOLD', label: 'Sold' },
                { value: 'WITHDRAWN', label: 'Withdrawn' }
              ]} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={4}>
            <Form.Item name={['address', 'streetNumber']} label='Street Number'>
              <Input autoFocus={true} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={['address', 'streetName']} label='Street Name'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={['address', 'unit']} label='Unit'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name={['address', 'buildingName']} label='Address Line 2'>
          <Input />
        </Form.Item>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item name={['address', 'city']} label='City'>
              <Input autoFocus={true} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={['address', 'state']} label='State'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name={['address', 'zip']} label='Zip'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name='neighborhood' label='Neighborhood'>
          <Input />
        </Form.Item>
        <Row gutter={12}>
          <Col span={8}>
            <Form.Item name='bedrooms' label='Bedrooms'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='bathrooms' label='Bathrooms'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name='squareFootage' label='Square Footage'>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name='propertyURL' label='Property URL'>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const toInput = (value: PropertyModel) => {
  return {
    ...value,
    expirationDate: toDate(value.expirationDate),
    listingDate: toDate(value.listingDate),
    listPrice: toNumber(value.listPrice + '')
  };
};

const toDate = (value: string | null) => {
  return value ? dayjs(value).format('YYYY-MM-DD') : null;
};

const toNumber = (value: string | null) => {
  const number = parseFloat(value || '');
  return isNaN(number) ? 0 : number;
};
