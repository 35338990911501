import React, { useState } from 'react';
import { Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { ConsumerModel, useFetcher } from '../../../lib';
import { Button, Content, Loading, SpaceBetween } from '../../ui';
import { ConsumerAddModal } from '../ConsumerAddModal';
import { ItemType } from 'antd/es/menu/hooks/useItems';

type ConsumersListProps = {
  menu?: ItemType[];
}

export const ConsumersList = ({ menu }: ConsumersListProps) => {
  const [addConsumer, setAddConsumer] = useState(false);
  const fetcher = useFetcher();
  const { data, error, isLoading } = useSWR('consumers', () => fetcher.get<ConsumerModel[]>('/consumers'));
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <Content menu={menu}>
      <SpaceBetween>
        <Typography.Title level={5}>Consumers</Typography.Title>
        <Space>
          <Button onClick={() => setAddConsumer(true)}>Create Consumer</Button>
        </Space>
      </SpaceBetween>
      <Table dataSource={data.data}>
        <Table.Column<ConsumerModel> title='Name' dataIndex='name' key='name' render={(_, consumer) => (
          <Link to={`${consumer.id}`}>{`${consumer.firstName} ${consumer.lastName}`}</Link>
        )} />
      </Table>
      <ConsumerAddModal isOpen={addConsumer} setOpen={setAddConsumer} />
    </Content>
  );
};
