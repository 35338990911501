import React, { useState } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Descriptions, Space, Typography } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { BrokerModel, useFetcher } from '../../../lib';
import { Button, Content, Loading, SpaceBetween } from '../../ui';
import { SystemsByBrokerList } from '../../system';
import { BrokerEditModal } from '../BrokerEditModal';

export const Broker = () => {
  const navigate = useNavigate();
  const { brokerId } = useParams();
  const [editBroker, setEditBroker] = useState(false);
  const fetcher = useFetcher();
  const { data, error, isLoading } = useSWR('brokers/' + brokerId, () => fetcher.get<BrokerModel>('/brokers/' + brokerId));
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <Content>
      <SpaceBetween>
        <Typography.Title level={5}>
          <Space>
            <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
            {data.data.name}
          </Space>
        </Typography.Title>
        <Space>
          <Button onClick={() => setEditBroker(true)}>Edit</Button>
        </Space>
      </SpaceBetween>
      <Descriptions layout='vertical'>
        <Descriptions.Item label='Name'>{data.data.name || '-'}</Descriptions.Item>
        <Descriptions.Item label='Property Push API URL'>{data.data.propertyPushUrl || '-'}</Descriptions.Item>
        <Descriptions.Item label='Token'>{data.data.token || '-'}</Descriptions.Item>
      </Descriptions>
      <BrokerEditModal brokerId={brokerId || '-'} isOpen={editBroker} setOpen={setEditBroker}/>
      <SystemsByBrokerList brokerId={brokerId || '-'} />
    </Content>
  );
};
