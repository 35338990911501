import React, { useState } from 'react';
import { formatAddress, useFetcher } from '../../../lib';
import useSWR from 'swr';
import { PropertyModel } from '../../../lib';
import { Button, Content, Loading, SpaceBetween } from '../../ui';
import { Space, Table, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { PropertyAddModal } from '../PropertyAddModal';

type PropertiesListProps = {
  systemId: string;
}

export const PropertiesList = ({ systemId }: PropertiesListProps) => {
  const [addProperty, setAddProperty] = useState(false);
  const fetcher = useFetcher();
  const {
    data,
    error,
    isLoading
  } = useSWR(`systems/${systemId}/properties`, () => fetcher.get<PropertyModel[]>(`/systems/${systemId}/properties`));
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <Content>
      <SpaceBetween>
        <Typography.Title level={5}>Properties</Typography.Title>
        <Space>
          <Button onClick={() => setAddProperty(true)}>Create Property</Button>
        </Space>
      </SpaceBetween>
      <Table dataSource={data.data}>
        <Table.Column<PropertyModel>
          title='MLS Number' dataIndex='mlsNumber' key='mlsNumber' width={120}
          render={(_, property) => (
            <Link to={`/admin/properties/${property.id}`}>{property.mlsNumber || '-'}</Link>
          )} />
        <Table.Column<PropertyModel>
          title='Address' dataIndex='address' key='address'
          render={(_, property) => formatAddress(property.address)}
        />
        <Table.Column<PropertyModel>
          title='Price' dataIndex='listPrice' key='listPrice'
          render={(listPrice) => '$' + listPrice}
        />
        <Table.Column<PropertyModel>
          title='Listing Date' dataIndex='listingDate' key='listingDate' />
        <Table.Column<PropertyModel>
          title='Status' dataIndex='status' key='status'
        />
      </Table>
      <PropertyAddModal isOpen={addProperty} setOpen={setAddProperty} systemId={systemId} />
    </Content>
  );
};
