import React from 'react';
import {Link} from "react-router-dom";

import './ModuleSelector.scss';

type Module = {
    name: string;
    url: string;
}

type ModuleSelectorProps = {
    modules: Module[];
}

export const ModuleSelector = ({modules}: ModuleSelectorProps) => {
    return (
        <div className="pc-module-selector">
            {modules.map(module => (
                <Link key={module.url} to={module.url}>{module.name}</Link>
            ))}
        </div>
    );
};
