import React from 'react';
import {CloseCircleFilled, MenuOutlined} from '@ant-design/icons';
import {Space} from "antd";
import {useNavigate} from "react-router-dom";

import './DisplayToken.scss';

type DisplayTokenProps = {
    removeToken: () => void;
    token: string;
}

export const DisplayToken = ({removeToken, token}: DisplayTokenProps) => {
    const navigate = useNavigate();
    return (
        <div className="pc-display-token">
            <Space size="large">
                <MenuOutlined onClick={() => navigate('/')}/>
                <Space>
                    <div>Token: ...{token.slice(-4)}</div>
                    <CloseCircleFilled onClick={removeToken}/>
                </Space>
            </Space>
        </div>
    );
};
