import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ConsumerModel, useFetcher } from '../../../lib';
import useSWR from 'swr';
import { Content, Loading, SpaceBetween } from '../../ui';
import { Descriptions, Space, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

export const Consumer = () => {
  const navigate = useNavigate();
  const { consumerId } = useParams();
  const fetcher = useFetcher();
  const { data, error, isLoading } = useSWR(`consumers/${consumerId}`, () => fetcher.get<ConsumerModel>(`/consumers/${consumerId}`));
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <Content>
      <SpaceBetween>
        <Typography.Title level={5}>
          <Space>
            <ArrowLeftOutlined style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
            <span>{data.data.firstName} {data.data.lastName}</span>
          </Space>
        </Typography.Title>
      </SpaceBetween>
      <Descriptions layout='vertical'>
        <Descriptions.Item label='Name'>{data.data.firstName} {data.data.lastName}</Descriptions.Item>
        <Descriptions.Item label='Email'>{data.data.email || '-'}</Descriptions.Item>
        <Descriptions.Item label='Phone'>{data.data.phone || '-'}</Descriptions.Item>
      </Descriptions>
    </Content>
  );
};
