import React from 'react';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import useSWR, { mutate } from 'swr';
import { BrokerModel, useFetcher } from '../../../lib';
import { Loading } from '../../ui';

type ConsumerAddModalProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const ConsumerAddModal = ({ isOpen, setOpen }: ConsumerAddModalProps) => {
  const fetcher = useFetcher();
  const { data, error, isLoading } = useSWR('brokers', () => fetcher.get<BrokerModel[]>('/brokers'));
  const [form] = Form.useForm();
  return (
    <Modal
      onCancel={() => setOpen(false)}
      onOk={() => {
        fetcher.post('/consumers', form.getFieldsValue())
          .then(() => {
            setOpen(false);
            form.resetFields();
            mutate('consumers');
          });
      }}
      open={isOpen}
      title='New Consumer'
    >
      {isLoading && (<Loading />)}
      {!isLoading && error && (
        <div>Error...</div>
      )}
      {!isLoading && !error && data && (
        <Form
          form={form}
          initialValues={{
            brokerId: data.data.length ? data.data[0].id : null,
          }}
          layout="vertical"
        >
          <Form.Item label='Broker' name='brokerId'>
            <Select
              options={data.data.map(broker => ({ label: broker.name, value: broker.id }))}
              style={{ width: '100%' }}
            />
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label='First Name' name='firstName'>
                <Input autoFocus={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Last Name' name='lastName'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label='Email' name='email'>
                <Input autoFocus={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Phone' name='phone'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};
