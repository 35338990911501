import React from 'react';
import { useFetcher } from '../../../lib';
import { Form, Input, Modal } from 'antd';
import { mutate } from 'swr';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

type SystemAddModalProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const SystemAddModal = ({ isOpen, setOpen }: SystemAddModalProps) => {
  const fetcher = useFetcher();
  const [form] = Form.useForm();
  return (
    <Modal
      onCancel={() => setOpen(false)}
      onOk={() => {
        fetcher.post('/systems', form.getFieldsValue())
          .then(() => {
            setOpen(false);
            form.resetFields();
            mutate('systems');
          })
      }}
      open={isOpen}
      title='New MLS System'
    >
      <Form
        {...layout}
        form={form}
      >
        <Form.Item name='name' label='Name' required={true}>
          <Input autoFocus={true} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
