import {Button} from "../../../../components";
import {PropertyModel, useFetcher} from "../../../../lib";


type PropertyReportSharedProps = {
    property: PropertyModel;
    consumerId: string;
}
export const PropertyReportShared = ({property, consumerId}: PropertyReportSharedProps) => {
    const fetcher = useFetcher();
    return (
        <>
            <Button onClick={() => {
                fetcher.post(`${consumerId}/properties/${property.id}/report-share`,)
                    .then(() => {
                        //ignore
                    })
            }}>
                Property Report Share
            </Button>
        </>
    )
}