import {SearchRequest} from "../Search/types";
import {Button} from "antd";
import React from "react";
import {useFetcher} from "../../../../lib";
import {useParams} from "react-router-dom";

type SaveSearchProps = {
    request: SearchRequest
}

export const SaveSearch = ({request}: SaveSearchProps) => {
    const fetcher = useFetcher();
    const {consumerId} = useParams();
    return (
        <Button onClick={() => {
            fetcher.post(`${consumerId}/properties/search-save`, request)
                .then(() => {
                    //ignore
                })
        }

        }>Save Search</Button>
    )
}