import { useState } from 'react';
import { FORMS_INQUIRED_OPTIONS, FormSwitcher } from '../FormSwitcher/FormSwitcher';
import { PropertyModel } from '../../../../lib';
import { Button } from '../../../../components';

export const InquiredAboutPropertyRequest = ({ property, consumerId }: {
  property: PropertyModel;
  consumerId: string;
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button onClick={() => setOpen(true)}>Inquired About Property</Button>
      <FormSwitcher
        title={'Inquired About Property'}
        forms={FORMS_INQUIRED_OPTIONS}
        isOpen={open}
        setOpen={setOpen}
        action={'inquired'}
        property={property}
        consumerId={consumerId} />
    </>

  );
};