import React, { useState } from 'react';
import {Button, Checkbox, Col, Form, Input, Row, Select} from 'antd';
import { useParams } from 'react-router-dom';
import { SearchRequest } from './types';
import { Properties } from './components';

import './Search.scss';
import {SaveSearch} from "../SaveSearch/SaveSearch";

export const Search = () => {
  const { consumerId } = useParams();
  const [search, setSearch] = useState<SearchRequest | null>(null);
  const [form] = Form.useForm();
  return (
    <div className='pc-search'>

      <Form<SearchRequest>
        form={form}
        layout='horizontal'
      >
        <b>Search</b>
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item label='Neighborhood' name='neighborhood'>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Button onClick={() => setSearch(form.getFieldsValue())}>Search</Button>
            <SaveSearch request={form.getFieldsValue()}/>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item label='Bathrooms From' name={['bathrooms', 'from']}>
              <Select options={[{label: '1', value: 1}, {label: '2', value: 2}, {label: '3', value: 3}, {label: '4', value: 4}, {label: '5+', value: 5}]} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='To' name={['bathrooms', 'to']}>
              <Select options={[{label: '1', value: 1}, {label: '2', value: 2}, {label: '3', value: 3}, {label: '4', value: 4}, {label: '5+', value: 5}]} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item label='Bedrooms From' name={['bedrooms', 'from']}>
              <Select options={[{label: '1', value: 1}, {label: '2', value: 2}, {label: '3', value: 3}, {label: '4', value: 4}, {label: '5+', value: 5}]} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='To' name={['bedrooms', 'to']}>
              <Select options={[{label: '1', value: 1}, {label: '2', value: 2}, {label: '3', value: 3}, {label: '4', value: 4}, {label: '5+', value: 5}]} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={6}>
            <Form.Item label='Price From' name={['price', 'from']}>
              <Select options={[
                {label: '$100,000', value: 100000},
                {label: '$200,000', value: 200000},
                {label: '$300,000', value: 300000},
                {label: '$400,000', value: 400000},
                {label: '$500,000', value: 500000},
                {label: '$600,000', value: 600000},
                {label: '$700,000', value: 700000},
                {label: '$800,000', value: 800000},
                {label: '$900,000', value: 900000},
                {label: '$1,000,000', value: 1000000},
              ]} />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label='To' name={['price', 'to']}>
              <Select options={[
                {label: '$100,000', value: 100000},
                {label: '$200,000', value: 200000},
                {label: '$300,000', value: 300000},
                {label: '$400,000', value: 400000},
                {label: '$500,000', value: 500000},
                {label: '$600,000', value: 600000},
                {label: '$700,000', value: 700000},
                {label: '$800,000', value: 800000},
                {label: '$900,000', value: 900000},
                {label: '$1,000,000', value: 1000000},
              ]} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label='Zips' name='zips'>
              <Checkbox.Group options={['55401', '55402', '55403', '55404', '55405', '55406', '55407', '55408', '55409', '55410']} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {search && <Properties consumerId={consumerId ?? '-'} search={search} />}
    </div>
  );
};
