import React from 'react';
import { useFetcher } from '../../../lib';
import useSWR, { mutate } from 'swr';
import { SystemModel } from '../../../lib';
import { Form, Input, Modal } from 'antd';
import { Loading } from '../../ui';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

type SystemEditModalProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  systemId: string;
}

export const SystemEditModal = ({ isOpen, setOpen, systemId }: SystemEditModalProps) => {
  const fetcher = useFetcher();
  const {
    data,
    error,
    isLoading
  } = useSWR('systems/' + systemId, () => fetcher.get<SystemModel>('/systems/' + systemId));
  const [form] = Form.useForm();
  return (
    <Modal
      onCancel={() => setOpen(false)}
      onOk={() => {
        fetcher.post('/systems/' + systemId, form.getFieldsValue())
          .then(() => {
            setOpen(false);
            form.resetFields();
            mutate('systems');
            mutate('systems/' + systemId);
          });
      }}
      open={isOpen}
      title='Edit Broker'
    >
      {isLoading && (<Loading />)}
      {!isLoading && error && (
        <div>Error...</div>
      )}
      {!isLoading && !error && data && (
        <Form
          {...layout}
          form={form}
          initialValues={data.data}
        >
          <Form.Item name='name' label='Name' required={true}>
            <Input autoFocus={true} />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
