import React, {ReactNode} from 'react';
import {Menu} from "antd";
import {ItemType} from "antd/es/menu/hooks/useItems";

import './Content.scss';

type ContentProps = {
    children: ReactNode;
    menu?: ItemType[]
}

export const Content = ({children, menu}: ContentProps) => {
    return (
        <div className={"pc-content" + (menu ? ' sidebar' : '')}>
            {menu && (
                <>
                    <Menu
                        items={menu}
                        mode="inline"
                    />
                    <div className="content">{children}</div>
                </>
            )}
            {!menu && children}
        </div>
    );
};
