import React, {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import useSWR from 'swr';
import {Favorite} from '../Favorite';
import {PropertyModel, useFetcher} from '../../../../lib';
import {Loading} from '../../../../components';
import {SentToFriendAction} from '../SentToFriendAction';
import {DrivingDirectionsAction} from '../DrivingDirectionsAction';

import './Property.scss';
import {ShowingRequest} from "../ShowingRequest/ShowingRequest";
import {MadeOfferRequest} from "../MadeOfferRequest/MadeOfferRequest";
import {InquiredAboutPropertyRequest} from "../InquiredAboutPropertyRequest/InquiredAboutPropertyRequest";
import {PropertyReportShared} from "../PropertyReportShared/PropertyReportShared";
import {PropertyReportRequest} from "../PropertyReportRequest/PropertyReportRequest";

export const Property = () => {
    const {consumerId, propertyId} = useParams();
    const fetcher = useFetcher();
    const {
        data,
        error,
        isLoading
    } = useSWR(`consumer/properties/${propertyId}`, () => fetcher.get<PropertyModel>(`${consumerId}/properties/${propertyId}`));
    const favorites = useSWR(`consumer/${consumerId}/favorites`, () => fetcher.get<PropertyModel[]>(consumerId + '/favorites'));
    useEffect(() => {
        fetcher.post(`${consumerId}/properties/${propertyId}/viewed`)
    }, [fetcher, consumerId, propertyId]);
    if (isLoading || favorites.isLoading) {
        return <Loading/>;
    }
    if (error || !data || favorites.error || !(favorites.data)) {
        return <div>Error...</div>;
    }
    const property = data.data;
    return (
        <div className='pc-property'>
            <img src={property.pictures[0]} alt='Property View'/>
            <div className='price'>
                ${property.listPrice}
            </div>
            <div className='status'>
                {property.status} | MLS# {property.mlsNumber}
            </div>
            <div className='address'>
                {`${property.address.streetNumber} ${property.address.streetName}`}
            </div>
            <div className='description'>
                {property.bedrooms} beds | {property.bathrooms} baths | {property.squareFootage} sq ft
                | {property.propertyType}
            </div>
            <div className='actions'>
                <Favorite consumerId={consumerId || '-'} favorites={favorites.data!.data} property={property}
                          size={50}/>
                <SentToFriendAction consumerId={consumerId || '-'} propertyId={propertyId || '-'} size={50}/>
                <DrivingDirectionsAction consumerId={consumerId || '-'} propertyId={`${property.id}`} size={50}/>
            </div>
            <div className='actions'>
                <ShowingRequest property={property} consumerId={consumerId || ''}/>
                <MadeOfferRequest property={property} consumerId={consumerId || ''}/>
                <InquiredAboutPropertyRequest property={property} consumerId={consumerId || ''}/>
            </div>
            <div className='actions'>
                <PropertyReportShared consumerId={consumerId || ''} property={property}/>
                <PropertyReportRequest property={property} consumerId={consumerId || ''}/>
            </div>
        </div>
    );
};
