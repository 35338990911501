import { SearchRequest } from '../Search/types';
import { Col, Form, Input, Row } from 'antd';
import React from 'react';
import { Button } from '../../../../components';
import useSWR, { mutate } from 'swr';
import { PropertyModel, useFetcher } from '../../../../lib';
import { useParams } from 'react-router-dom';
import { InquiredInGeneralRequest } from '../InquiredInGeneralRequest/InquiredInGeneralRequest';

export const Preferences = () => {
  const [form] = Form.useForm();
  const { consumerId } = useParams();
  const fetcher = useFetcher();

  const subscribeNeighborhood = (name: string) => {
    if (!name) {
      return;
    }
    fetcher.post(`${consumerId}/settings/neighborhood/subscribe`, { name: name })
      .then(() => {
        form.setFieldValue('neighborhood', '');
        mutate('consumer/neighborhoods');
      });

  };
  return <div className='pc-search'>
    <Form<SearchRequest>
      form={form}
      layout='horizontal'
    >
      <b>Preferences</b>
      <Row gutter={12}>
        <Col>
          <Form.Item label='Neighborhood' name='neighborhood'>
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Button onClick={() => {
            subscribeNeighborhood(form.getFieldValue('neighborhood'));
          }}>Subscribe for Neighborhood</Button>
        </Col>


      </Row>
      <Row>
        <NeighborhoodList consumerId={consumerId || ''} />
      </Row>
      <br />
      <Row>
        <Col>
          <InquiredInGeneralRequest consumerId={consumerId || ''} />
        </Col>
      </Row>
    </Form>
  </div>;
};


const NeighborhoodList = ({ consumerId }: {
  consumerId: string
}) => {
  const fetcher = useFetcher();
  const {
    data
  } = useSWR('consumer/neighborhoods', () => fetcher.post<PropertyModel[]>(consumerId + '/settings/neighborhoods'));

  const unsubscribeNeighborhood = (name: string) => {
    fetcher.post(`${consumerId}/settings/neighborhood/unsubscribe`, { name: name })
      .then(() => {
        //ignore
        mutate('consumer/neighborhoods');
      });

  };
  return <>
    {data && data.data.map((name, index) =>
      <NeighborhoodItem key={`${name}${index}`} name={name + ''} deleteName={unsubscribeNeighborhood} />
    )}
  </>;
};

const NeighborhoodItem = ({ name, deleteName }: { name: string, deleteName: (name: string) => void }) => {
  return <div style={{ padding: 10, marginLeft: 15, display: 'flex', border: '1px gray solid' }}>
    <b style={{ marginRight: 10 }}>{name}</b>
    <Button onClick={() => deleteName(name)}>X</Button>
  </div>;
};