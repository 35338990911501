import React from 'react';
import { Form, Input, Modal } from 'antd';
import { mutate } from "swr"
import { useFetcher } from '../../../lib';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

type BrokerAddModalProps = {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

export const BrokerAddModal = ({ isOpen, setOpen }: BrokerAddModalProps) => {
  const fetcher = useFetcher();
  const [form] = Form.useForm();
  return (
    <Modal
      onCancel={() => setOpen(false)}
      onOk={() => {
        fetcher.post('/brokers', form.getFieldsValue())
          .then(() => {
            setOpen(false);
            form.resetFields();
            mutate('brokers');
          })
      }}
      open={isOpen}
      title='New Broker'
    >
      <Form
        {...layout}
        form={form}
      >
        <Form.Item name='name' label='Name' required={true}>
          <Input autoFocus={true} />
        </Form.Item>
        <Form.Item name='propertyPushUrl' label='Property Push API URL'>
          <Input autoFocus={true} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
