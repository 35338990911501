import React from 'react';
import { CompassOutlined } from '@ant-design/icons';
import { useFetcher } from '../../../../lib';

type DrivingDirectionsActionProps = {
  consumerId: string;
  propertyId: string;
  size: 30 | 50
}

export const DrivingDirectionsAction = ({ consumerId, propertyId, size }: DrivingDirectionsActionProps) => {
  const fetcher = useFetcher();
  return (
    <CompassOutlined
      onClick={() => {
        fetcher.post(`${consumerId}/properties/${propertyId}/driving-directions-viewed`)
          .then(() => alert('Driving directions viewed event triggered'));
      }}
      style={{ fontSize: size }}
    />
  );
};
