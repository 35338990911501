import React from 'react';
import { Link } from 'react-router-dom';
import { MailOutlined } from '@ant-design/icons';
import { useFetcher } from '../../../../lib';

type SentToFriendActionProps = {
  consumerId: string;
  propertyId: string;
  size: 30 | 50
}

export const SentToFriendAction = ({ consumerId, propertyId, size }: SentToFriendActionProps) => {
  const fetcher = useFetcher();
  return (
    <Link
      to='mailto:?subject=address%20%7C%20MLS%3A%20xxx&body=Property%20URL'
      onClick={() => fetcher.post(`${consumerId}/properties/${propertyId}/sent`)}
    >
      <MailOutlined style={{ fontSize: size }} />
    </Link>
  );
};
