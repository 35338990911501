import React from 'react';
import { PropertyModel, useFetcher } from '../../../lib';
import useSWR, { mutate } from 'swr';
import { Col, Form, Input, Modal, Row, Select } from 'antd';
import { Loading } from '../../ui';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
};

type PropertyEditModalProps = {
  isOpen: boolean;
  propertyId: string;
  setOpen: (isOpen: boolean) => void;
}

export const PropertyEditModal = ({ isOpen, propertyId, setOpen }: PropertyEditModalProps) => {
  const fetcher = useFetcher();
  const {
    data,
    error,
    isLoading
  } = useSWR(`properties/${propertyId}`, () => fetcher.get<PropertyModel>(`/properties/${propertyId}`));
  const [form] = Form.useForm();
  return (
    <Modal
      onCancel={() => setOpen(false)}
      onOk={() => {
        fetcher.post(`/properties/${propertyId}`, form.getFieldsValue())
          .then(() => {
            setOpen(false);
            mutate(`properties/${propertyId}`);
            mutate(`properties/${propertyId}/activities`)
          });
      }}
      open={isOpen}
      title='Edit Property'
      width={800}
    >
      {isLoading && (<Loading />)}
      {!isLoading && error && (
        <div>Error...</div>
      )}
      {!isLoading && !error && data && (
        <Form
          {...layout}
          form={form}
          initialValues={data.data}
          layout='vertical'
        >
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item label='Status' name='status'>
                <Select options={[
                  { value: 'ACTIVE', label: 'Active' },
                  { value: 'EXPIRED', label: 'Expired' },
                  { value: 'PENDING', label: 'Pending' },
                  { value: 'SOLD', label: 'Sold' },
                  { value: 'WITHDRAWN', label: 'Withdrawn' }
                ]} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='Current Price' name='listPrice'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};
