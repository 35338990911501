import React, { ReactNode } from 'react';

import './Button.scss';

type ButtonProps = {
  children: ReactNode;
  onClick?: () => void;
}

export const Button = ({ children, ...rest }: ButtonProps) => {
  return (
    <button className='pc-button' {...rest}>
      {children}
    </button>
  );
};
