import React from 'react';
import { Table, Typography } from 'antd';
import useSWR from 'swr';
import { PropertyActivityModel, useFetcher } from '../../../lib';
import { Content, Loading, SpaceBetween } from '../../ui';
import dayjs from 'dayjs';

type PropertyActivitiesListProps = {
  propertyId: string;
}

export const PropertyActivitiesList = ({ propertyId }: PropertyActivitiesListProps) => {
  const fetcher = useFetcher();
  const {
    data,
    error,
    isLoading
  } = useSWR(`properties/${propertyId}/activities`, () => fetcher.get<PropertyActivityModel[]>(`/properties/${propertyId}/activities`));
  if (isLoading) {
    return <Loading />;
  }
  if (error || !data) {
    return <div>Error...</div>;
  }
  return (
    <Content>
      <SpaceBetween>
        <Typography.Title level={5}>Activity</Typography.Title>
      </SpaceBetween>
      <Table dataSource={data.data}>
        <Table.Column<PropertyActivityModel>
          title='Timestamp' dataIndex='occurredAt' key='occurredAt' width={200}
          render={(_, activity) => dayjs(activity.occurredAt).format('D/MM/YYYY hh:mm A')}
        />
        <Table.Column<PropertyActivityModel>
          title='Type' dataIndex='type' key='type'
        />
      </Table>
    </Content>
  );
};
