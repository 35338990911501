import React, { ReactNode } from 'react';
import { Property } from 'csstype';

import './SpaceBetween.scss';

type SpaceBetweenProps = {
  alignItems?: Property.AlignItems;
  children: ReactNode;
};

export const SpaceBetween = ({ alignItems, children }: SpaceBetweenProps) => {
  return (
    <div className="pc-space-between" style={{ alignItems: alignItems }}>
      {children}
    </div>
  );
};
