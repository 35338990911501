import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Fetcher } from '../../lib';
import { ConsumerSwitcher, Property } from '../../apps/consumer';
import {ConsumerPage} from "../../apps/consumer/components/ConsumerPage/ConsumerPage";

type RootPageProps = {
  removeToken: () => void;
  token: string;
}

export const RootPage = ({ removeToken, token }: RootPageProps) => {
  return (
    <Fetcher baseURL='/api/consumer' removeToken={removeToken} token={token}>
      <Routes>
        <Route index={true} element={<ConsumerSwitcher />} />
        <Route path=':consumerId'>
          <Route index={true} element={<ConsumerPage />} />
          <Route path='properties/:propertyId' element={<Property />} />
        </Route>
      </Routes>
    </Fetcher>
  );
};
