import React from 'react';
import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { mutate } from 'swr';
import { PropertyModel, useFetcher } from '../../../../lib';

type FavoriteProps = {
  consumerId: string;
  favorites: PropertyModel[];
  property: PropertyModel;
  size: 30 | 50
}

export const Favorite = ({ consumerId, favorites, property, size }: FavoriteProps) => {
  const fetcher = useFetcher();
  const favoritesId = favorites.map(p => p.id);
  if (favoritesId.includes(property.id)) {
    return (
      <HeartFilled
        onClick={() => {
          fetcher.delete(`${consumerId}/favorites/${property.id}`)
            .then(() => mutate(`consumer/${consumerId}/favorites`));
        }}
        style={{ fontSize: size }}
      />
    );
  }
  return (
    <HeartOutlined
      onClick={() => {
        fetcher.post(`${consumerId}/favorites`, { propertyId: property.id })
          .then(() => mutate(`consumer/${consumerId}/favorites`));
      }}
      style={{ fontSize: size }}
    />
  );
};
