import {PropertyModel, useFetcher} from "../../../../lib";
import {Button} from "../../../../components";

type PropertyReportRequestProps = {
    property: PropertyModel;
    consumerId: string;
}
export const PropertyReportRequest = ({property, consumerId}: PropertyReportRequestProps) => {
    const fetcher = useFetcher();

    return (
        <>
            <Button onClick={() => {
                fetcher.post(`${consumerId}/properties/${property.id}/report-request`,)
                    .then(() => {
                        //ignore
                    })
            }}>
                Property Report Request
            </Button>
        </>
    )
}