import React, { ReactNode } from 'react';
import { FetcherProvider } from './FetcherContext';
import { DisplayToken } from './DisplayToken';

type FetcherProps = {
  baseURL: string;
  children: ReactNode;
  removeToken: () => void;
  token: string;
}

export const Fetcher = ({ baseURL, children, removeToken, token }: FetcherProps) => {
  return (
    <FetcherProvider baseURL={baseURL} token={token}>
      <>
        <DisplayToken token={token} removeToken={removeToken} />
        {children}
      </>
    </FetcherProvider>
  );
};
