import React, { useState } from 'react';
import { useLocalStorage } from '@rehooks/local-storage';
import { Button, Form, Input } from 'antd';

import './TokenProvider.scss';

type FormType = {
  token: string;
}

type TokenProviderProps = {
  children: (token: string, removeToken: () => void) => JSX.Element;
}

export const TokenProvider = ({ children }: TokenProviderProps): JSX.Element => {
  const [storedToken, setStoredToken] = useLocalStorage<string | null>('token', null);
  const [token, setToken] = useState<string | null>(storedToken);
  const [form] = Form.useForm();
  if (token === null) {
    return (
      <Form<FormType>
        className='pc-token-provider-form'
        form={form}
        onFinish={(values) => {
          setStoredToken(values.token);
          setToken(values.token);
        }}
      >
        <Form.Item name='token' label='Token' rules={[{ required: true }]}>
          <Input autoFocus={true} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 21 }}>
          <Button type='primary' htmlType='submit'>
            Submit
          </Button>
        </Form.Item>
      </Form>
    );
  }
  return children(token, () => {
    setStoredToken(null);
    setToken(null);
  });
};
